import React, { useState, useEffect } from "react";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";

import "../../visuals/design/skSpecial.css";

import Bc25_line from "./linegraphs/Bc25_line";
import Bc24_line from "./linegraphs/Bc24_line";
import Bc23_line from "./linegraphs/Bc23_line";
import Bc22_line from "./linegraphs/Bc22_line";
import Bc21_line from "./linegraphs/Bc21_line";
import Bc20_line from "./linegraphs/Bc20_line";

import BottleColumns from "../../visuals/columns/BottleColumns";

const BottlesBC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchTerm, setSearchTerm] = useState("");
  const [bottles, setBottles] = useState([]);
  const [activeYear, setActiveYear] = useState(2025);
  const [orderIDSort, setOrderIDSort] = useState("asc"); // Default to ascending
  const [page, setPage] = useState(0);
  const ordersPerPage = 50;

  useEffect(() => {
    const fetchAndFormatBottles = async (year) => {
      const yearMap = {
        2025: "five",
        2024: "four",
        2023: "three",
        2022: "two",
        2021: "one",
        2020: "zero",
      };
      const url = `${process.env.REACT_APP_SERVER_URL}/bc/${yearMap[year]}`;
      try {
        const response = await fetch(url);
        const data = await response.json();

        // Sort the fetched data by order_id (ascending by default)
        const sortedData = [...data].sort((a, b) => {
          const orderA = a.order_id ? parseInt(a.order_id, 10) : 0;
          const orderB = b.order_id ? parseInt(b.order_id, 10) : 0;
          return orderA - orderB; // Default: ascending order
        });

        setBottles(sortedData);
        setPage(0);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAndFormatBottles(activeYear);
  }, [activeYear]);

  const handleButtonClick = (year) => setActiveYear(year);

  const filterRows = (rows, searchTerm) =>
    rows.filter((row) =>
      Object.values(row)
        .map((value) => (value || "").toString().toLowerCase())
        .join(" ")
        .includes(searchTerm.toLowerCase())
    );

  let filteredRows = filterRows(bottles, searchTerm);

  // Apply sorting for order_id
  if (orderIDSort) {
    filteredRows = [...filteredRows].sort((a, b) => {
      const orderA = a.order_id ? parseInt(a.order_id, 10) : 0;
      const orderB = b.order_id ? parseInt(b.order_id, 10) : 0;
      return orderIDSort === "asc" ? orderA - orderB : orderB - orderA;
    });
  }

  const paginatedRows = filteredRows.slice(page * ordersPerPage, (page + 1) * ordersPerPage);

  const graphComponents = {
    2025: <Bc25_line />,
    2024: <Bc24_line />,
    2023: <Bc23_line />,
    2022: <Bc22_line />,
    2021: <Bc21_line />,
    2020: <Bc20_line />,
  };

  const graphComponent = graphComponents[activeYear] || <Bc25_line />;

  const toggleOrderIDSort = () => {
    setOrderIDSort((prevSort) => (prevSort === "asc" ? "desc" : "asc"));
  };

  return (
    <div style={{ marginLeft: "2em" }}>
      <h2 style={{ marginBottom: "0px" }}>US Bottle Sales</h2>


      {/* Line Graph Buttons */}
      <div style={{ marginBottom: "-20px", display: "flex", gap: "8px" }}>
        {[2020, 2021, 2022, 2023, 2024, 2025].map((year) => (
          <button
            key={year}
            className="dateButton"
            onClick={() => handleButtonClick(year)}
          >
            <span className="year">{year}</span>
          </button>
        ))}
      </div>



      <div style={{ maxWidth: "1000px" }}>{graphComponent}</div>

      {/* Search Box */}
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-box"
      />

      {/* Table */}
      <table style={{ color: colors.SKcustom[10] }}>
        <thead>
          <tr>
            {BottleColumns.map((col) => (
              <th
                key={col.field}
                onClick={() => col.field === "order_id" && toggleOrderIDSort()}
                style={{
                  cursor: col.field === "order_id" ? "pointer" : "default",

                }}
              >
                {col.headerName}{" "}
                {col.field === "order_id" && (orderIDSort === "asc" ? "🔼" : "🔽")}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {paginatedRows.map((row) => (
            <tr key={row._id}>
              {BottleColumns.map((col) => (
                <td key={col.field} style={{ fontSize: "1.1em", lineHeight: "1.1em", border: 'none' }}>
                  {col.field === "date" && row[col.field]
                    ? new Date(row[col.field]).toISOString().split("T")[0] // Ensures correct date without time zone shifts
                    : row[col.field]}
                </td>

              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div style={{
        margin: '2px 0px 10px -12px', backgroundColor: "#666666", maxWidth: '250px', padding: '5px 0px'
      }}>
        <button disabled={page === 0} onClick={() => setPage(page - 1)} className="pageButton" style={{ color: colors.SKcustom[17] }}>
          Previous
        </button>
        <span style={{ color: colors.SKcustom[17] }}>
          {page + 1} of {Math.ceil(filteredRows.length / ordersPerPage)}
        </span>
        <button
          disabled={(page + 1) * ordersPerPage >= filteredRows.length}
          onClick={() => setPage(page + 1)}
          className="pageButton" style={{ color: colors.SKcustom[17] }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BottlesBC;
