const BottleColumns = [
  {
    field: "order_id",
    headerName: "order ID",
    flex: 0.03,
    renderCell: (params) => (
      <span style={{ fontSize: "11px" }}>{params.value}</span>
    ),
  },
  {
    field: "date",
    headerName: "Date",
    flex: 0.04,
    sortable: false,
    renderCell: (params) => (
      <span style={{ fontSize: "11px" }}>{params.value}</span>
    ),
  },
  {
    field: "units",
    headerName: "Qty",
    flex: 0.02,
    renderCell: (params) => (
      <span style={{ fontSize: "11px" }}>{params.value}</span>
    ),
  },
  {
    field: "product",
    headerName: "Product",
    flex: 0.1,
    renderCell: (params) => (
      <span style={{ fontSize: "11px" }}>{params.value}</span>
    ),
  },
  {
    field: "holding",
    headerName: "Holding",
    renderCell: (params) => (
      <span style={{ fontSize: "11px" }}>{params.value}</span>
    ),
  },
  {
    field: "subtotal",
    headerName: "Subtotal",
    flex: 0.05,
    valueFormatter: ({ value }) =>
      Number(value ?? 0).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
  },
  {
    field: "state",
    headerName: "State",
    flex: 0.05,
    renderCell: (params) => (
      <span style={{ fontSize: "11px" }}>{params.value}</span>
    ),
  },
  {
    field: "source",
    headerName: "Source",
    flex: 0.05,
    renderCell: (params) => (
      <span style={{ fontSize: "11px" }}>{params.value}</span>
    ),
  },
];

export default BottleColumns;
