import React, { useState, useEffect, useRef } from "react";
import { tokens } from "../../../../theme";
import { useTheme } from "@mui/material";
import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);

function Bc25_line() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/bc/five`
      );
      const dataW = await response.json();
      if (response.ok) {
        setOrders(dataW);
      }
    };
    fetchOrders();
  }, []);

  const quantitiesPerDay = {};
  orders.forEach((order) => {
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", {
        month: "2-digit",
        day: "2-digit",
        timeZone: "UTC",
      }).format(date);
    };

    const formattedDate = formatDate(order.date);
    const quantity = order.units;

    if (quantitiesPerDay[formattedDate]) {
      quantitiesPerDay[formattedDate] += quantity;
    } else {
      quantitiesPerDay[formattedDate] = quantity;
    }
  });

  const labels = Object.keys(quantitiesPerDay);
  const quantities = Object.values(quantitiesPerDay);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Units sold",
        data: quantities,
        backgroundColor: colors.SKcustom[100],
        borderColor: colors.SKcustom[100],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      datalabels: {
        align: "end",
        anchor: "end",
        offset: 10,
        color: colors.SKcustom[200],
        font: { size: 10 },
      },
    },
    scales: {
      x: {
        ticks: {
          source: "auto",
          color: colors.SKcustom[200],
        },
        grid: { display: false },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: 70,
        ticks: { color: colors.SKcustom[100] },
      },
    },
  };

  const graphContainerStyle = {
    width: "1000px",
    overflowX: "auto",
  };

  const graphStyle = {
    width: "1500px",
  };

  const graphContainerRef = useRef(null);

  useEffect(() => {
    if (graphContainerRef.current) {
      graphContainerRef.current.scrollLeft =
        graphContainerRef.current.scrollWidth;
    }
  }, []);

  return (
    <div>
      <h4>2025</h4>
      <div style={graphContainerStyle} ref={graphContainerRef}>
        <div style={graphStyle}>
          <Line data={data} options={options} width={100} height={30} />
        </div>
      </div>
    </div>
  );
}

export default Bc25_line;
